<template>
    <div class="input-ribbons" :class="get_state()" ref="root">

        <div    class="input-header" v-if="ribbons.header">
                {{ ribbons.header }} </div>

        <label :for="ribbons.name" v-if="ribbons.label">
                {{ ribbons.label }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_ribbon_notifying() || is_mod_notifying()" @mouseover="reset_response_state()">
                {{ response }} </div> </transition>

        <div class="input-frame" :class="ribbons.theme">

            <div class="form-column" v-for="(ribbon,i) in ribbons.options" :key="i">

                <div class="input-field input-ribbon" 
                    :class="get_ribbon_checked(ribbon)"
                    @click="update_ribbon(ribbon)" >

                        <div v-html="get_icon(ribbon)" class="input-ribbon-icon"></div>
                        <h6> {{ ribbon.replace(/-/g,' ') }} </h6> 
                        </div>

                <div class="input-button" 
                    :class="get_mod_checked(ribbon,option.name)"
                    @click="update_mod(option.name)"
                    v-for="(option,j) of mod.options[ribbon]" :key="j" >
                    {{ option.label.replace(/-/g,' ') }} </div>

                <input  type="text" 
                        class="hidden"
                        :name="ribbons.name" 
                        :value="get_ribbon_value()" 
                        :required="ribbons.required" >

                <input  type="text" 
                        class="hidden"
                        :name="mod.name" 
                        :value="get_mod_value()" 
                        :required="mod.required" >

    </div></div></div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-ribbon',
    data () { return {
        state: [],
        mod_state: [],
        response: '',
    }},
    props: [ 'ribbons','mod' ],
    computed: {
        ...mapGetters([ 'cart','fields','status' ])
    },
    methods: {
        is_active () {
            return this.has_state('active')
        },
        async update_ribbon (ribbon) {

            let valid = this.ribbons.options.filter(platform => platform == ribbon).length > 0
            let value = this.toggle_ribbon_value(ribbon)

            let bundle_ribbon = this.get_ribbon_bundle(value,valid)

            await this.$store.dispatch('update_field', bundle_ribbon)

            //If ribbon is de-selected, de-select related mods
            if (value.length == 0 || this.is_ribbon_created() && !this.get_ribbon_created().value.includes(ribbon)) {
                this.remove_mods(ribbon)
            }

            else switch(ribbon) {
                
                case 'tiktok':
                case 'snapchat': 
                    let option = this.mod.options[ribbon][0].name
                    this.update_mod(option)
                    // break

                // default: 
                //     this.update_mod()
                //     break
            }

            this.set_state()

        },
        async update_mod (option = '') {

            let value = this.toggle_mod_value(option)
            let valid = false
            
            //If ribbons don't have any mod options, invalid
            if (this.is_mod_created()) {

                let ribbons = this.get_ribbon_created().value.split(',')
                let options = value.split(',')

                for (const o of options) {
                    let other_options_exist = false
                    for (const r of ribbons) {
                        let platform = this.mod.options[r]
                        other_options_exist = platform.filter(item => item.name == o).length > 0
                    }        
                    if (other_options_exist) valid = true
                }
            }

            if (!this.is_mod_created()) valid = true

            
            let bundle_mod = this.get_mod_bundle(value,valid)


            await this.$store.dispatch('update_field', bundle_mod)
            

            this.set_state()

        },
        async remove_mods (ribbon) {

            for (const option of this.mod.options[ribbon]) {

                let value = this.is_mod_created() ? this.get_mod_created().value : ''
                
                if (value.includes(option.name)) {
                    let arr = value.split(',')
                    let index = arr.indexOf(option.name)
                    arr.splice(index,1)
                    value = arr.join(',')
                }
                
                let valid = value.length > 0
                
                let bundle_mod = this.get_mod_bundle(value,valid)

                await this.$store.dispatch('update_field', bundle_mod)
                
            }
            
            this.set_state()

        },
        get_ribbon_bundle (value,valid) {

            let bundle = {
                name: this.ribbons.name,
                type: this.ribbons.type,
                step: this.ribbons.step,
                value: value,
                header: this.ribbons.header,
                label: this.ribbons.label,
                options: this.ribbons.options,
                theme: this.ribbons.theme,
                required: this.ribbons.required,
                valid: valid,
                notify: false,
            }

            return bundle

        },
        get_mod_bundle (value,valid) {
            
            let options = []

            for (const platform in this.mod.options) {
                for (const option of this.mod.options[platform])
                    options.push(option.name)
            }

            let bundle = {
                name: this.mod.name,
                type: this.mod.type,
                step: this.mod.step,
                value: value,
                header: this.mod.header,
                label: this.mod.label,
                options: options,
                theme: this.mod.theme,
                required: this.mod.required,
                valid: valid,
                notify: false,
            }

            return bundle

        },
        reset_mod_bundle (name,options) {
            let bundle = {
                type: 'checkbox',
                name: name,
                value: '',
                options: options,
                valid: false,
                required: this.mod.required,
                step: this.mod.step,
                notify: false,
            }

            return bundle

        },
        get_ribbon_value () {
            return this.is_ribbon_created() ? this.cart.find(row => row.name == this.ribbons.name).value.split(',') : ''
        },
        get_mod_value () {
            let value = this.is_mod_created() ? this.cart.find(row => row.name == this.mod.name).value : ''

            if (value.length == 0) value = []
            else value.split(',')
            
            return this.is_mod_created() ? value : ''
        },
        get_ribbon_created () {
            return this.cart.filter(el => el.name == this.ribbons.name)[0]
        },
        get_mod_created () {
            return this.cart.filter(el => el.name == this.mod.name)[0]
        },
        is_ribbon_created () {
            return this.cart.findIndex(row => row.name == this.ribbons.name) > -1
        },
        is_mod_created () {
            return this.cart.findIndex(row => row.name == this.mod.name) > -1
        },
        is_ribbon_checked (value) {
            return this.get_ribbon_value().indexOf(value) > -1
        },
        is_mod_checked (option) {
            return this.get_mod_value().indexOf(option) > -1
        },
        get_ribbon_checked (value) {
            return this.is_ribbon_checked(value) ? 'active' : ''
        },
        get_mod_checked (ribbon,option) {
            return this.is_mod_checked(option) && this.is_ribbon_checked(ribbon) ? 'active' : ''
        },
        get_icon (name) {
            switch (name) {
                case 'youtube':     return `<i class="fa fa-youtube"></i>`
                case 'facebook':    return `<i class="fa fa-facebook-official"></i>`
                case 'instagram':   return `<i class="fa fa-instagram"></i>`
                case 'snapchat':    return `<i class="fa fa-snapchat"></i>`
                case 'tiktok':      return `<img src="` + require('@/asset/img/tiktok-white.png') + `" alt="Tik Tok Logo" class="img-icon">`
            }
        },
        get_style () {
            let style = ''
            if (this.ribbons.required) style = 'required'
            if (this.ribbons.valid) style = 'valid'
            return style
        },
        get_ribbon_options () {
            let options = []
            for (const ribbon of this.ribbons.mods) {
                options.push(ribbon.header)
            }
            return options
        },
        get_sub_options () {
            let options = []
            for (const ribbon of this.ribbons.mods) {
                for (const option of ribbon.options)
                options.push(option)
            }
            return options
        },
        get_state () {
            let all = [...this.state,...this.mod_state]
            let state = [...new Set(all)]
            return state.join(' ')
        },
        set_state () {

            let valid_ribbon = this.is_ribbon_created() ? this.get_ribbon_created().valid : false
            let valid_mod = this.is_mod_created() ? this.get_mod_created().valid : false
            let has_ribbon = this.has_ribbon_state('valid')
            let has_mod = this.has_mod_state('valid')
            let has_ribbon_invalid = this.has_ribbon_state('invalid')
            let has_mod_invalid = this.has_mod_state('invalid')

            if (valid_ribbon && valid_mod && !has_ribbon && !has_mod)
                this.state.push('valid')
            
            else if ((!valid_ribbon && !this.ribbons.required) &&
                (!valid_mod && !this.mod.required)) 
                this.remove_state('valid') 

            else if ((!valid_ribbon && this.ribbons.required && !has_ribbon_invalid) ||
                (!valid_mod && this.mod.required && !has_mod_invalid))
                this.state.push('invalid') 

        },
        has_ribbon_state (name) {
            return this.state.indexOf(name) > -1
        },
        has_mod_state (name) {
            return this.mod_state.indexOf(name) > -1
        },
        remove_ribbon_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        remove_mod_state (name) {
            this.mod_state = this.mod_state.filter(c => c !== name)
        },
        get_ribbon_response () {

            switch (this.ribbons.name) {
                default: return 'This is a required field.'; break
            }

        },
        get_mod_response () {

            switch (this.mod.name) {
                default: return ' Select at least one option.'; break
            }

        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        reset_response_state () {

            let that = this

            this.response = ''
            this.$store.dispatch('set_status','')

            let ribbon_value = this.is_ribbon_created() ? this.get_ribbon_created().value : ''
            let mod_value = this.is_mod_created() ? this.get_mod_created().value : ''

            let ribbon_valid = this.is_ribbon_created() ? this.get_ribbon_created().valid : false
            let mod_valid = this.is_mod_created() ? this.get_mod_created().valid : false


            let bundle_ribbon = this.get_ribbon_bundle(ribbon_value,ribbon_valid)
            let bundle_mod = this.get_mod_bundle(mod_value,mod_valid)

            this.$store.dispatch('update_field', bundle_ribbon)
            this.$store.dispatch('update_field', bundle_mod)

        },
        notify () {

            let message = ''

            if (this.ribbons.valid) 
                this.reset_response_state()
            
            else if (!this.ribbons.valid && !this.ribbons.required) 
                this.reset_response_state()
            
            else if (!this.ribbons.valid) 
                message += this.get_ribbon_response()


            if (this.mod.valid) 
                this.reset_response_state()
            
            else if (!this.mod.valid && !this.mod.required) 
                this.reset_response_state()
            
            else if (!this.mod.valid) 
                message += this.get_mod_response()
            
            this.response = message

        },
        is_ribbon_notifying () {

            let is_valid = this.is_ribbon_created() && this.get_ribbon_created().valid
            let is_required = this.ribbons.required

            let notify = this.is_ribbon_created() ? this.get_ribbon_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        is_mod_notifying () {

            let is_valid = this.is_mod_created() && this.get_mod_created().valid
            let is_required = this.ribbons.required

            let notify = this.is_mod_created() ? this.get_mod_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate (name) {
            return this.cart[name] !== undefined && this.
            this.set_state()
        },
        toggle_ribbon_value (name) {
            
            let value = this.is_ribbon_created() ? this.get_ribbon_created().value.split(',') : []

            if (this.is_ribbon_created()) {
                if (value.indexOf(name) < 0)
                    value.push(name)
                else
                    value.splice(value.indexOf(name),1)
            }
            else 
                value.push(name)


            value = value.join(',')

            return value

        },
        toggle_mod_value (name) {
            
            let value = this.is_mod_created() ? this.get_mod_created().value.split(',') : []


            if (this.is_mod_created()) {
                if (value.indexOf(name) < 0)
                    value.push(name)
                else
                    value.splice(value.indexOf(name),1)
            }
            else 
                value.push(name)

            if (value.length == 0) value = ''
            else value = value.join(',')

            return value

        },
    },
    mounted () {

        this.$store.dispatch('register',{
            name: this.ribbons.name,
            valid: false,
            required: this.ribbons.required,
            step: this.ribbons.step,
        })

        this.$store.dispatch('register',{
            name: this.ribbons.mod_name,
            valid: false,
            required: this.ribbons.required,
            step: this.ribbons.step,
        })


        let mods = [...this.$refs.root.querySelectorAll('*')]

        let delay = 0.2
        let increment = 0.01
        let offset = 0.5

        for (const el of mods) {
            el.style.animationDelay = delay + 's'
            delay += increment * offset
        }

    },
}
</script>
<style>
</style>