<template>
    <div class="input-field input-file" :class="get_state()" ref="root">

        <div    class="input-header" v-if="file.header">
                {{ file.header }} </div>

        <label :for="file.name" v-if="file.label">
                {{ file.label }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <div class="input-frame" :class="file.theme">
            <div    class="input-button" 
                    :class="has_file()"
                    @click="choose()" >

                    {{ file.placeholder }} </div> </div>

        <input  type="file"
                class="hidden"
                :name="file.name + '[]'"  
                :required="file.required" 
                :accept="file.options"
                @input="update()"
                ref="picker">
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-file',
    data () { return {
        state: [],
        response: '',
    }},
    props: ['file'],
    computed: {
        ...mapGetters([ 'cart','fields','status' ])
    },
    methods: {
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.filter(el => el.name == this.file.name).length > 0
        },
        has_file (option) {
            return this.get_value() === option ? 'active' : ''
        },
        is_notifying () {

            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.file.required

            let notify = this.is_created() ? this.get_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        get_created () {
            return this.cart.filter(el => el.name == this.file.name)[0]
        },
        get_value () {
            return this.is_created() ? this.get_created().value : ''
        },
        get_state () {
            return this.state.join(' ')
        },
        set_state () {

            if (this.get_created().valid && !this.has_state('valid')) {
                this.state.push('valid')
            }
            
            else if (!this.get_created().valid && !this.file.required) {
                this.remove_state('valid')
            }

            else if (!this.get_created().valid && this.file.required && !this.has_state('invalid')) {
                this.state.push('invalid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            switch (this.file.name) {
                default: return 'Make sure you pick one option, this is a required field.'; break
            }

        },
        notify () {

            if (this.file.valid) 
                this.reset_response_state()
            
            else if (!this.file.valid && !this.file.required) 
                this.reset_response_state()
            
            else if (!this.file.valid) 
                this.response = this.get_response()
            
        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        reset_response_state () {

            this.response = ''

            let bundle = {
                name: this.file.name,
                type: this.file.type,
                step: this.file.step,
                value: this.get_value(),
                header: this.file.header,
                label: this.file.label,
                options: this.file.options,
                theme: this.file.theme,
                required: this.file.required,
                valid: this.is_created() ? this.get_created().valid : this.file.valid,
                notify: false,
                el: null,
            }

            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('set_status', '')

        },
        exists_icon (option) {
            return this.get_icon(option).length > 0
        },
        get_icon (option) {
            switch(option) {
                case 'sports': return `<i class="fa fa-futbol-o" aria-hidden="true"><i>`
                case 'gaming': return `<i class="fa fa-gamepad"><i>`
                case 'automotive': return `<i class="fa fa-car"><i>`
                case 'fashion': return `<i class="fa fa-shopping-bag"><i>`
                case 'beauty': return `<i class="fa fa-diamond"><i>`
                case 'lifestyle': return `<i class="fa fa-home"><i>`
                case 'travel': return `<i class="fa fa-plane"><i>`
                case 'family': return `<i class="fa fa-group"><i>`
                case 'music': return `<i class="fa fa-headphones"><i>`
                case 'food': return `<i class="fa fa-cutlery"><i>`
                case 'health': return `<i class="fa fa-heartbeat"><i>`
                case 'business': return `<i class="fa fa-suitcase"><i>`
                default: return ''
            }
        },
        choose () {
            this.$refs.picker.click()
        },
        update () {

            let value = this.$refs.picker.value
            let valid = value.length > 0

            let bundle = {
                name: this.file.name,
                type: this.file.type,
                step: this.file.step,
                value: value,
                header: this.file.header,
                label: this.file.label,
                options: this.file.options,
                theme: this.file.theme,
                required: this.file.required,
                valid: valid,
                notify: this.file.notify,
                el: this.$refs.picker,
            }

            this.$store.dispatch('update_field', bundle)

            this.$emit('click',value)

            this.set_state()

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate () {
            this.set_state()
        },
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
}
</script>