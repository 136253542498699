<template>
    <div class="input-field input-radio" :class="get_state()" ref="root">

        <div    class="input-header" v-if="radio.header">
                {{ radio.header }} </div>

        <label :for="radio.name" v-if="radio.label">
                {{ radio.label }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <div class="input-frame" :class="radio.theme">
            <div    v-for="(option,i) of radio.options" :key="i" 
                    :class="is_selected(option)"
                    class="input-button" 
                    @click="update(i)" >

                    <div class="input-button-icon" v-if="exists_icon(option)" v-html="get_icon(option)"></div>
                    {{ option.replace('-',' ') }} </div> </div>

        <input  type="text"
                class="hidden"
                :name="radio.name"  
                :value="get_value()"
                :required="radio.required" >
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-radio',
    data () { return {
        state: [],
        response: '',
    }},
    props: ['radio'],
    computed: {
        ...mapGetters([ 'cart','fields','status' ])
    },
    methods: {
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.filter(el => el.name == this.radio.name).length > 0
        },
        is_selected (option) {
            return this.get_value() === option ? 'active' : ''
        },
        is_notifying () {

            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.radio.required

            let notify = this.is_created() ? this.get_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        get_created () {
            return this.cart.filter(el => el.name == this.radio.name)[0]
        },
        get_value () {
            return this.is_created() ? this.get_created().value : ''
        },
        get_state () {
            return this.state.join(' ')
        },
        set_state () {

            if (this.get_created().valid && !this.has_state('valid')) {
                this.state.push('valid')
            }
            
            else if (!this.get_created().valid && !this.radio.required) {
                this.remove_state('valid')
            }

            else if (!this.get_created().valid && this.radio.required && !this.has_state('invalid')) {
                this.state.push('invalid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            switch (this.radio.name) {
                default: return 'Make sure you pick one option, this is a required field.'; break
            }

        },
        notify () {

            if (this.radio.valid) 
                this.reset_response_state()
            
            else if (!this.radio.valid && !this.radio.required) 
                this.reset_response_state()
            
            else if (!this.radio.valid) 
                this.response = this.get_response()
            
        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        reset_response_state () {

            this.response = ''

            let bundle = {
                name: this.radio.name,
                type: this.radio.type,
                step: this.radio.step,
                value: this.get_value(),
                header: this.radio.header,
                label: this.radio.label,
                options: this.radio.options,
                theme: this.radio.theme,
                required: this.radio.required,
                valid: this.is_created() ? this.get_created().valid : this.radio.valid,
                notify: false,
            }

            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('set_status', '')

        },
        exists_icon (option) {
            return this.get_icon(option).length > 0
        },
        get_icon (option) {
            switch(option) {
                case 'sports': return `<i class="fa fa-futbol-o" aria-hidden="true"><i>`
                case 'gaming': return `<i class="fa fa-gamepad"><i>`
                case 'automotive': return `<i class="fa fa-car"><i>`
                case 'fashion': return `<i class="fa fa-shopping-bag"><i>`
                case 'beauty': return `<i class="fa fa-diamond"><i>`
                case 'lifestyle': return `<i class="fa fa-home"><i>`
                case 'travel': return `<i class="fa fa-plane"><i>`
                case 'family': return `<i class="fa fa-group"><i>`
                case 'music': return `<i class="fa fa-headphones"><i>`
                case 'food': return `<i class="fa fa-cutlery"><i>`
                case 'health': return `<i class="fa fa-heartbeat"><i>`
                case 'business': return `<i class="fa fa-suitcase"><i>`
                default: return ''
            }
        },
        update (index) {

            let valid = this.radio.options[index] !== undefined
            let value = this.radio.options[index]

            let bundle = {
                name: this.radio.name,
                type: this.radio.type,
                step: this.radio.step,
                value: value,
                header: this.radio.header,
                label: this.radio.label,
                options: this.radio.options,
                theme: this.radio.theme,
                required: this.radio.required,
                valid: valid,
                notify: this.radio.notify,
            }

            this.$store.dispatch('update_field', bundle)

            this.$emit('click',value)

            this.set_state()

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate () {
            this.set_state()
        },
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
}
</script>