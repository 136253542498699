<template>
    <div class="form-navigation">
        <div class="form-brand">
            <a href="https://www.alfangroup.com" target="_blank"><img :src="get_brand()" alt="Alfan Group Brand"></a>
            <a href="/"><h3>Campaign Creator</h3></a>
        </div>

        <div class="form-steps" @click="toggle_steps()">
            
            <span class="form-total" v-if="!show_status()">
                Step {{ get_current() + 1 }}: {{ step.replace('-',' ') }} 
            </span>
            <div class="form-notification" v-if="show_status()">
                {{ get_header() }}
            </div>
            <div class="form-marker" v-if="status !== 'reviewing' && status !== 'responded' && has_path()">
                <div class="form-mark" v-for="(mark,i) of path" :key="i" :class="get_marker_status(mark)"></div>
            </div>
        </div>
        
        <div class="form-prev" @click="go_prev()" v-if="is_prev() && !is_reviewable()">Prev</div>

        <div class="form-next" @click="go_next()" :class="is_next()" v-if="!is_reviewable()">Next</div>
        <div class="form-next" @click="review()" v-if="is_reviewable() && status !== 'reviewing'">Review</div>
    </div>
</template>
<script>

    import { mapGetters } from 'vuex'
    export default {
        name: 'form-navigation',
        data () { return {
            valid_page: false,
        }},
        computed: {
            ...mapGetters([
                'step',
                'path',
                'status',
                'hovering',
                'fields',
                'cart',
            ])
        },
        methods: {
            exists_step () {
                return this.step !== undefined && this.step.length > 0
            },
            show_status () {
                return this.is_navigating() || this.is_reviewing()
            },
            is_navigating () {
                return this.status == 'navigating'
            },
            is_reviewing () {
                return this.status == 'reviewing'
            },
            unreview () {
                this.$store.dispatch('set_status','')
            },
            validate () {
                return true
            },
            get_current () {
                if (this.exists_step()) {
                    let index = this.path.findIndex(i => i.name == this.step)
                    return index
                }
                return 0
            },
            is_next() {
                let has_next_path = this.get_current() + 1 < this.path.length ? true : false
                let has_valid_page = this.validate()


                return has_next_path && has_valid_page ? '' : 'hide'

            },
            go_next() {
                this.$store.dispatch('next')
            },
            is_prev() {
                return this.get_current() > 0
            },
            go_prev() {
                let name = this.path[this.get_current() - 1].name
                this.$store.dispatch('set_step',name);
            },
            toggle_steps() {
                if (this.status == 'navigating')
                    this.$store.dispatch('set_status','')
                else
                    this.$store.dispatch('set_status','navigating')
            },
            get_brand () {
                return require('@/asset/img/brand-mark.png')
            },
            get_header () {
                if (this.status == 'navigating' && this.hovering.length > 0) return this.hovering
                return this.status
            },
            is_active_mark (id) {
                return id == this.step
            },
            is_reviewable () {
                // return true
                return this.path.length > 1 && this.step == this.path[this.path.length - 1].name
            },
            has_path () {
                return this.path.length > 1
            },
            review () {
                this.$store.dispatch('review')
            },
            get_page_errors () {
                let page = []
                for (const key in this.fields) {
                    if (this.fields[key].step == this.step)
                        page.push(this.fields[key])
                }
                let required = page.filter(el => el.required == true)
                let fail = required.filter(el => el.valid == false)
                let errors = '\n----------------------\n'
                for (const el of fail) {
                    errors += '\n' + this.capitalize(el.name.replace('_',' '))
                }
                return 'There is a problem with the following required fields: ' + errors
            },
            get_form_errors () {
                let required = []
                for (const key in this.fields) {
                    if (this.fields[key].required)
                        required.push(this.fields[key])
                }
                let fail = required.filter(el => el.valid == false)
                let steps = '\n----------------------\n'
                for (const el of fail) {
                    steps += '\nStep ' + (this.path.indexOf(el.step) + 1) + ': ' + this.capitalize(el.name.replace('_',' '))
                }
                return 'Fix the errors with required fields on the following steps to move forward: ' + steps
            },
            get_marker_status (step) {

                let index = this.path.findIndex(i => i.name == step.name)

                if (this.is_active_mark(step.name)) {
                    return 'active'
                }
                else if (index > this.get_current()) {
                    return ''
                }
                else if (step.valid) {
                    return 'valid'
                } 
                else if (!step.valid) {
                    return 'invalid'
                } 
                return ''
            },
            capitalize (word) {
                return word.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
            },
        },
        updated () {
        },
    }

</script>