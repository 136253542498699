<template>
    <div class="form-loading">
        <h2>Your form is being processed.</h2>
        <div class="form-loading-animation"> <div></div><div></div> </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'view-loading',
    computed: {},
    methods: {},
}
</script>