<template>
    <div class="input-field input-textarea" :class="get_state()" ref="root">

        <div    class="input-header" v-if="textarea.header">
                {{ textarea.header }} </div>

        <label :for="textarea.name" v-if="textarea.label">
                {{ textarea.label }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <textarea  @focus="wake()"
                @blur="sleep()"
                @input="update()"
                :type="textarea.type"
                :name="textarea.name"  
                :required="textarea.required" 
                :placeholder="textarea.placeholder"
                v-model="textarea.value" > </textarea>
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-textarea',
    data () { return {
        state: [],
        response: '',
        peek: false,
    }},
    props: ['textarea'],
    computed: {
        ...mapGetters([ 'cart','fields','status' ])
    },
    methods: {
        wake () {
            this.state.push('active')
        },
        sleep () {

            if (this.is_active()) 
                this.remove_state('active')

            this.notify()
            
        },
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.filter(el => el.name == this.textarea.name).length > 0
        },
        is_notifying () {

            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.textarea.required

            let notify = this.is_created() ? this.get_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        get_created () {
            return this.cart.filter(el => el.name == this.textarea.name)[0]
        },
        get_state () {

            if (this.state.length == 0 && !this.peek) {
                this.set_state()
                this.peek = true
            }

            return this.state.join(' ')

        },
        set_state () {

            let valid = this.is_created() ? this.get_created().valid : this.textarea.valid

            if (valid && !this.has_state('valid')) {
                this.state.push('valid')
                this.remove_state('invalid')
            }
            
            else if (this.textarea.value.length == 0 || 
                    (!valid && !this.textarea.required)) {
                this.remove_state('valid')
                this.remove_state('invalid')
            }

            else if (!valid && this.textarea.required && !this.has_state('invalid')) {
                this.state.push('invalid')
                this.remove_state('valid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            if (this.textarea.pattern !== undefined)
                switch (this.textarea.pattern) {
                    case 'currency': return 'The currency is submitted as shown in the label. Only numbers, commas & decimals allowed.'
                }

            else switch (this.textarea.type) {
                case 'email': return 'Your email format should be in the following style: example@domain.com'; break
                case 'phone': return 'Phone # Format: Numbers and Dashes. ex.555-555-5555.'; break
            }

        },
        notify () {

            if (this.textarea.valid) 
                this.reset_response_state()
            
            else if (!this.textarea.valid && !this.textarea.required) 
                this.reset_response_state()
            
            else if (!this.textarea.valid) 
                this.response = this.get_response()
            
        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        reset_response_state () {

            this.response = ''

            let bundle = {
                name: this.textarea.name,
                type: this.textarea.type,
                step: this.textarea.step,
                value: this.is_created() ? this.get_created().value : '',
                header: this.textarea.header,
                label: this.textarea.label,
                options: this.textarea.options,
                theme: this.textarea.theme,
                required: this.textarea.required,
                valid: this.is_created() ? this.get_created().valid : false,
                notify: false,
            }

            this.$store.dispatch('update_field', bundle)

        },
        update (index) {

            let bundle = {
                name: this.textarea.name,
                type: this.textarea.type,
                step: this.textarea.step,
                value: this.textarea.value,
                header: this.textarea.header,
                label: this.textarea.label,
                options: this.textarea.options,
                theme: this.textarea.theme,
                required: this.textarea.required,
                valid: this.validate(),
                notify: this.textarea.notify,
            }

            this.$store.dispatch('update_field', bundle)

            this.$emit('click')

            this.set_state()

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate () {

            return true

        },
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
}
</script>