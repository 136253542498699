<template>
    <div class="input-field input-select" :class="get_state()" ref="root">

        <div    class="input-header" v-if="select.header">
                {{ select.header }} </div>

        <label :for="select.name" v-if="select.label">
                {{ select.label }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <div class="input-frame" :class="is_open()">

            <div    class="input-button"
                    :class="is_selected()"
                    v-if="!opened"
                    @click="open()"
                    @focus="wake()"
                    @blur="sleep()" > {{ get_button_value() }} </div>

            <div    v-for="(option,i) of select.options" :key="i" 
                    :class="get_option_state(i)"
                    class="input-button input-option" 
                    @click="update(i)"
                    @focus="wake()"
                    @blur="sleep()" >
                    {{ option }} </div>
                    
            <div class="input-frame-close" @click="close"></div>
            
        </div>

        <input  type="text"
                class="hidden"
                :name="select.name"  
                :value="get_value()"
                :required="select.required" >
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-select',
    data () { return {
        state: [],
        value: '',
        response: '',
        selected: -1,
        opened: false,
    }},
    props: ['select'],
    computed: {
        ...mapGetters([ 'cart','fields','status' ])
    },
    methods: {
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.filter(el => el.name == this.select.name).length > 0
        },
        is_selected (option) {
            return this.selected > -1 ? 'active' : ''
        },
        is_notifying () {
            let is_created = this.is_created()
            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.select.required
            let is_notifying = this.status == 'notifying'

            let should_respond = is_created && is_notifying && !is_valid && is_required
            
            if (should_respond && this.get_created().notify) {
                this.notify()
                return true
            }
            return false
        },
        is_open () {
            return this.opened ? 'open' : ''
        },
        get_created () {
            return this.cart.filter(el => el.name == this.select.name)[0]
        },
        get_value () {
            return this.is_created() ? this.get_created().value : ''
        },
        get_state () {
            return this.state.join(' ')
        },
        get_button_value () {
            return this.is_created() ? this.get_created().value : this.select.placeholder
        },
        set_state () {

            if (this.get_created().valid && !this.has_state('valid')) {
                this.state.push('valid')
            }
            
            else if (!this.get_created().valid && !this.select.required) {
                this.remove_state('valid')
            }

            else if (!this.get_created().valid && this.select.required && !this.has_state('invalid')) {
                this.state.push('invalid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            switch (this.select.name) {
                default: return 'Make sure you pick one option, this is a required field.'; break
            }

        },
        notify () {

            if (this.select.valid) 
                this.reset_response_state()
            
            else if (!this.select.valid && !this.select.required) 
                this.reset_response_state()
            
            else if (!this.select.valid) 
                this.response = this.get_response()
            
        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        get_option_state (index) {
            let select_state = this.selected == index ? 'active' : ''
            let open_state = this.is_open()
            return select_state + ' ' + open_state
        },
        reset_response_state () {

            let that = this

            setTimeout(function () {

                this.response = ''

                let bundle = {
                    name: that.select.name,
                    type: that.select.type,
                    step: that.select.step,
                    value: that.get_value(),
                    header: that.select.header,
                    label: that.select.label,
                    options: that.select.options,
                    theme: that.select.theme,
                    required: that.select.required,
                    valid: that.get_created().valid,
                    notify: false,
                }

                that.$store.dispatch('update_field', bundle)

            },500)

        },
        update (index) {

            this.opened = false
            this.selected = index
            let valid = this.select.options[index] !== undefined
            let value = this.select.options[index]

            let bundle = {
                name: this.select.name,
                type: this.select.type,
                step: this.select.step,
                value: value,
                header: this.select.header,
                label: this.select.label,
                options: this.select.options,
                theme: this.select.theme,
                required: this.select.required,
                valid: valid,
                notify: this.select.notify,
            }

            this.$store.dispatch('update_field', bundle)

            this.$emit('click',value)

            this.set_state()

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate () {
            this.set_state()
        },
        open () {
            this.opened = true
        },
        close () {
            if (this.opened) {
                this.opened = false
            }
        }
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
}
</script>