<template>
    <div class="input-field input-file-link" :class="get_state()" ref="root">

        <div    class="input-header" v-if="file.header">
                {{ file.header }} </div>

        <label :for="file.name" v-if="file.label">
                {{ get_label() }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <div class="input-frame" :class="file.theme" v-if="!popup">
            <div    class="input-button" @click="open()" >
                    {{ file.placeholder }} </div> </div>

        <div class="input-popup" v-if="popup">

            <div    class="input-popup-close" @click="close()">
                    <i class="fa fa-close"></i> </div> 

            <div    :for="file.name"
                    class="input-popup-label"> 
                    Upload to 
                    <a :href="file.url" target="_blank">{{ file.sitename }}</a>
                    then paste the link </div>
                    
            <transition name="fade">
                <div class="input-popup-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                    {{ response }} </div> </transition>

            <input  @focus="wake()"
                    @blur="sleep()"
                    @input="update()"
                    :type="file.type"
                    :name="file.name"  
                    :required="file.required" 
                    :placeholder="file.placeholder"
                    v-model="file.value" > 

            <div    class="input-button" @click="upload()" >
                    {{ file.placeholder }} </div> </div>
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-file-link',
    data () { return {
        state: [],
        response: '',
        popup: false,
        peek: false,
    }},
    props: ['file'],
    computed: {
        ...mapGetters([ 'cart','fields','status','currency' ])
    },
    methods: {
        wake () {
            this.state.push('active')
        },
        sleep () {

            if (this.is_active()) 
                this.remove_state('active')

            this.notify()
            
        },
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.filter(el => el.name == this.file.name).length > 0
        },
        is_notifying () {

            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.file.required

            let notify = this.is_created() ? this.get_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        get_created () {
            return this.cart.filter(el => el.name == this.file.name)[0]
        },
        get_state () {

            if (this.state.length == 0 && !this.peek) {
                this.set_state()
                this.peek = true
            }

            return this.state.join(' ')

        },
        set_state () {

            let valid = this.is_created() ? this.get_created().valid : this.validate()
            let value = this.is_created() ? this.get_created().value : this.file.value

            if (valid && !this.has_state('valid')) {
                this.state.push('valid')
                this.remove_state('invalid')
            }
            
            else if (value.length == 0) {
                    this.remove_state('valid')
                    this.remove_state('invalid')
            }

            else if (!valid && !this.has_state('invalid')) {
                this.state.push('invalid')
                this.remove_state('valid')
            }
            
            else if (!valid && !this.has_state('invalid')) {
                this.state.push('invalid')
                this.remove_state('valid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            if (this.file.pattern !== undefined)
                switch (this.file.pattern) {
                    case 'website': return `Required Field: Make sure the website is valid.  Accepted formats (http://google.com, www.google.com, google.com)`
                    case 'currency': return 'Required Field: The currency is submitted as shown in the label. Only numbers, commas & decimals allowed.'
                    default: return `Required Field: Don't leave empty`
                }

            else switch (this.file.type) {
                case 'email': return 'Required Field: Your email format should be in the following style: example@domain.com'; break
                case 'phone': return 'Required Field: Numbers and Dashes. ex.555-555-5555.'; break
                default: return `Required Field: Don't leave empty`
            }

        },
        notify () {

            if (this.file.valid) 
                this.reset_response_state()
            
            else if (!this.file.valid && !this.file.required) 
                this.reset_response_state()
            
            else if (!this.file.valid) 
                this.response = this.get_response()
            
        },
        get_pattern () {
            
            let pattern = ''

            if (this.file.pattern !== undefined) 
                switch (this.file.pattern) {
                    case 'website': pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm; break
                    default: pattern = ''; break
                }

            else switch (this.file.type) {
                case 'email': pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; break
                case 'phone': pattern = /^(?=.*[0-9])[- +()0-9]+$/g; break
                default: pattern = ''; break
            }

            return new RegExp(pattern)

        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        reset_response_state () {

            this.response = ''

            let bundle = {
                name: this.file.name,
                type: this.file.type,
                step: this.file.step,
                value: this.file.value,
                header: this.file.header,
                label: this.file.label,
                options: this.file.options,
                theme: this.file.theme,
                required: this.popup,
                valid: this.is_created() ? this.get_created().valid : this.file.valid,
                notify: false,
            }

            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('set_status', '')

            this.set_state()

        },
        update (index) {


            let bundle = {
                name: this.file.name,
                type: this.file.type,
                step: this.file.step,
                value: this.file.value,
                header: this.file.header,
                label: this.file.label,
                options: this.file.options,
                theme: this.file.theme,
                required: this.popup,
                valid: this.validate(),
                notify: this.file.notify,
            }

            this.$store.dispatch('update_field', bundle)

            if (this.is_created())

            this.set_state()

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate (e) {

            let value = String(this.file.value).toLowerCase()

            let valid = this.get_pattern().test(value)
            
            return valid

        },
        get_label () {

            let label = this.file.label
            
            if (this.file.unit === undefined) return label

            switch (this.file.unit) {
                case 'global_currency': 
                    if (this.currency.length > 0)
                        label += ' (' + this.currency + ')'
            }

            return label

        },
        upload () {
            this.$emit('click','upload')
        },
        open () {
            this.popup = true

            let bundle = {
                name: this.file.name,
                type: this.file.type,
                step: this.file.step,
                value: this.file.value,
                header: this.file.header,
                label: this.file.label,
                options: this.file.options,
                theme: this.file.theme,
                required: true,
                valid: this.validate(),
                notify: this.file.notify,
            }

            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('rerequire',this.file.name)

            this.set_state()

        },
        close () {
            this.popup = false

            let bundle = {
                name: this.file.name,
                type: this.file.type,
                step: this.file.step,
                value: this.file.value,
                header: this.file.header,
                label: this.file.label,
                options: this.file.options,
                theme: this.file.theme,
                required: false,
                valid: this.validate(),
                notify: this.file.notify,
            }


            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('unrequire',this.file.name)

            this.set_state()

        },
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
}
</script>