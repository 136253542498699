<template>
    <div class="input-field input-select-multiple" :class="get_state()" ref="root">

        <div    class="input-header" v-if="select.header">
                {{ select.header }} </div>

        <label :for="select.name" v-if="select.label">
                {{ select.label }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <div class="input-frame">

            <div    class="input-button"
                    :class="is_selected()"
                    v-if="!opened"
                    @click="open()"
                    @focus="wake()"
                    @blur="sleep()" > {{ get_button_value() }} </div>

            <div    v-for="(option,i) of select.options" :key="i" 
                    :class="get_option_state(i)"
                    class="input-button input-option" 
                    @click="update(i)"
                    @focus="wake()"
                    @blur="sleep()" >
                    {{ option }} </div>
                    
            <div class="input-frame-close" @click="close">
                <i class="fa fa-close"></i>
            </div>

            <input  type="text" 
                    class="input-textbox" 
                    placeholder='Define the "Other" option'
                    @input="update_other()"
                    v-model="select.other"
                    v-if="is_other() && !is_open()" >
            
        </div>

        <input  type="text"
                class="hidden"
                :name="select.name"  
                :value="get_value()"
                :required="select.required" >
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-select-multiple',
    data () { return {
        state: [],
        value: '',
        response: '',
        selected: [],
        opened: false,
        clone: null,
    }},
    props: ['select'],
    computed: {
        ...mapGetters([ 'cart','fields','status' ])
    },
    methods: {
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.findIndex(el => el.name == this.select.name) > -1
        },
        is_selected () {

            let is_empty = true

            if (this.is_created())
                is_empty = this.get_created().value.length > 0 ? false : true  
            
            return !is_empty ? 'active' : ''

        },
        is_notifying () {

            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.select.required

            let notify = this.is_created() ? this.get_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        is_open () {
            return this.opened ? 'open' : ''
        },
        is_other () {

            let is_other = false

            if (this.is_created()) {

                //Check if other is selected
                is_other = this.get_created().value.indexOf('Other') > -1

                //If it isn't, erase any previously entered value
                if (!is_other) this.select.other = ''

            }

            return this.is_created() ? is_other : false

        },
        get_created () {
            return this.cart.filter(el => el.name == this.select.name)[0]
        },
        get_value () {

            let value = this.is_created() ? this.get_created().value : ''

            if (value.length == 0) value = []
            else value.split(',')

            return this.is_created() ? value : ''

        },
        get_state () {
            return this.state.join(' ')
        },
        get_button_value () {
            return this.is_created() && this.get_created().value.length > 0 ? this.get_created().value : this.select.placeholder
        },
        set_state () {
            

            if (this.is_open()) this.state.push('open')
            else this.remove_state('open')

            if (!this.is_created()) return

            let valid = this.get_created().valid

            if (valid && !this.has_state('valid')) {
                this.state.push('valid')
            }
            
            else if (!valid && !this.select.required) {
                this.remove_state('valid')
            }

            else if (!valid && this.select.required && !this.has_state('invalid')) {
                this.state.push('invalid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            switch (this.select.name) {
                default: return 'Make sure you pick at least one option, this is a required field.'; break
            }

        },
        notify () {

            if (this.select.valid) 
                this.reset_response_state()
            
            else if (!this.select.valid && !this.select.required) 
                this.reset_response_state()
            
            else if (!this.select.valid) 
                this.response = this.get_response()
            
        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        get_option_state (index) {
            let select_state = this.selected.indexOf(index) > -1 ? 'active' : ''
            let open_state = this.is_open()
            return select_state + ' ' + open_state
        },
        reset_response_state () {

            this.response = ''

            let bundle = {
                name: this.select.name,
                type: this.select.type,
                step: this.select.step,
                value: this.get_value(),
                header: this.select.header,
                label: this.select.label,
                options: this.select.options,
                theme: this.select.theme,
                required: this.select.required,
                valid: this.is_created() ? this.get_created().valid : this.select.valid,
                notify: false,
            }

            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('set_status', '')

        },
        update (index) {

            // this.opened = false
            this.toggle_selected(index)

            let name = this.select.options[index]
            let valid = this.select.options.indexOf(name) > -1
            let value = this.toggle_value(name)

            if (value.length == 0) valid = false
            

            let bundle = {
                name: this.select.name,
                type: this.select.type,
                step: this.select.step,
                value: value,
                header: this.select.header,
                label: this.select.label,
                options: this.select.options,
                theme: this.select.theme,
                required: this.select.required,
                valid: valid,
                notify: this.select.notify,
                other: this.select.other,
            }

            // if (this.is_created())

            this.$store.dispatch('update_field', bundle)

            this.$emit('click',value)

            this.set_state()


        },
        update_other () {

            // if (this.is_created())

            let bundle = {
                name: this.select.name,
                type: this.select.type,
                step: this.select.step,
                value: this.get_created().value,
                other: this.select.other,
                header: this.select.header,
                label: this.select.label,
                options: this.select.options,
                theme: this.select.theme,
                required: this.select.required,
                valid: this.get_created().valid,
                notify: this.select.notify,
            }

            this.$store.dispatch('update_field', bundle)

            this.set_state()

        },
        toggle_value (name) {

            let arr = this.is_created() && this.get_created().value.length > 0 ? this.get_created().value.split(',') : []


            if (arr.indexOf(name) < 0)
                arr.push(name)
            else
                arr.splice(arr.indexOf(name),1)

            let value = ''

            if (arr.length > 0) value = arr.join(',')


            return value

        },
        toggle_selected (index) {
            
            let i = this.selected.indexOf(index)

            if (i < 0) this.selected.push(index)
            else this.selected.splice(i,1)

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate () {
            this.set_state()
        },
        async open () {

            this.opened = true
            await this.set_state()
            this.load_selected()

            //add clone to body
            this.clone = this.$refs.root.cloneNode(true)
            document.querySelector('body').append(this.clone)

            //loop through options and add click handler
            let that = this
            let list = this.clone.querySelectorAll('.input-option')


            for (var i = 0, len = list.length; i < len; i++) { 
                list[i].addEventListener('click',(e) => {

                    var nodes = Array.prototype.slice.call( document.querySelector('body > .input-select-multiple.open > .input-frame').children );
                    let index = nodes.indexOf(e.target)

                    that.update(index)

                    if (e.target.classList.value.indexOf('active') > -1)
                        e.target.classList.remove('active')
                    else
                        e.target.classList.add('active')
                    
                },false);

                if (that.selected.indexOf(i) > -1)
                    list[i].classList.add('active')
            }
            this.clone.querySelector('.input-frame-close').addEventListener('click',() => {
                that.close()
            })
        },
        close () {
            this.opened = false
            document.querySelector('body > .input-select-multiple').remove()
            this.clone = null
            this.set_state()
        },
        load_selected () {
            if (this.is_created()) {
                let values = this.get_created().value.split(',')
                this.selected = []
                values.forEach(val => {
                    let index = this.select.options.indexOf(val)
                    if (index > -1) this.selected.push(index)
                })
            }
        },
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
    beforeUpdate() {
        // this.load_selected()
    },
}
</script>