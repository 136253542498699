<template>
    <div class="form-cart">
        <div class="form-items"></div>
        <h3>Form</h3>
    </div>
</template>
<script>
export default {
    name: 'form-cart',
}
</script>
<style scoped>

    .form-cart {display: none;}
    
</style>