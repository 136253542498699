<template>
    <div id="campaign-creator">
        <FormImagery />
        <FormNavigation />
        <FormCart />
        <transition name="fade" mode="out-in"> <FormStage v-if="is_creating()"/> </transition>
        <transition name="fade" mode="out-in"> <FormReview v-if="is_reviewing() || is_submitting()" /> </transition>
        <transition name="fade-quick" mode="out-in"> <FormLoading v-if="is_submitting()" /> </transition>
        <transition name="fade-quick" mode="out-in"> <FormResponse v-if="is_responded()" /> </transition>
    </div>
</template>
<script>

    import { mapGetters } from 'vuex'

    import FormImagery from '@/comp/FormImagery'
    import FormNavigation from '@/comp/FormNavigation'
    import FormCart from '@/comp/FormCart'
    import FormStage from '@/comp/FormStage'
    import FormResponse from '@/comp/FormResponse'
    import FormLoading from '@/comp/FormLoading'
    import FormReview from '@/comp/FormReview'

    export default {
        name: 'view-form',
        computed: {
            ...mapGetters([ 'status' ]),
        },
        methods: {
            is_reviewing () {
                return this.status == 'reviewing'
            },
            is_submitting () {
                return this.status == 'submitting'
            },
            is_responded () {
                return this.status == 'responded'
            },
            is_creating () {
                return !this.is_reviewing() && !this.is_submitting() && !this.is_responded()
            },
        },
        mounted () { this.$store.dispatch('init') },
        components: {
            FormImagery,
            FormNavigation,
            FormCart,
            FormStage,
            FormResponse,
            FormLoading,
            FormReview,
        }
    }

</script>
<style scoped>

    .campaign-creator {
        position: relative;
        height: 100vh;
        width: 100%;

        background-color: #0b0d0d;
        overflow: hidden;
        color: white;
    }

    * {box-sizing: border-box;}

</style>
