<template>
    <form>
        <div class="form-messaging">
            <h3>Please review the following statement and if all the information is as you'd like, submit your campaign and we will be in touch shortly!</h3>
            <h4>If any of the information isn't correct, clicking the detail will bring you back to the spot in the form where you can change the information.</h4>
        </div>
        <div class="form-review-step">
            <div    v-for="(el,i) in get_cart()" :key="i" 
                    v-html="render_sentence(el).outerHTML"
                    class="span"
                    @click="jump(el)"> </div>
        </div>
        <!-- <h6>Thank you for taking the time to fill out the form!</h6> -->
        <button @click="submit($event)">Create Campaign</button>
        <input type="hidden" name="bee" value="" placeholder="">
    </form>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    name: 'form-review',
    data () {
        return {
            editing: false,
            el: '',
            step: '',
            files: null,
            language: {
                campaign_type: 'Campaign. ',
                campaign_sub: 'I want to market the campaign using',
                social_platform: 'on',
                platform_mod: ['with a','. '],
                cost_type: `I'd like to take a performance based approach and pay`,
                cost_amount: 'to the amount of $',
                episode_amount: ['I would like to make','episodes for the campaign. '],
                digital_influencers: `I would like to include`,
                num_influencers: `influencers in the show. `,
                show_genre: [`I would like a `,`based show. `],
                show_description: `I would describe the show as the following:`,
                influencer_specialty: [`I'd like to use an influencer that specializes in the`,`genre. `],
                influencer_reach: [`I need an influencer who can reach an audience of `,`people. `],
                influencer_gender: 'They should be a',
                influencer_country: 'influencer from',
                audience_country: 'who can reach an audience in',
                audience_age: ['who are in the ','age range. '],
                brand_name: 'I am creating the campaign on behalf of the brand "',
                product_name: ['" to market','. '],
                brand_website: ['The brand website is ','. '],
                campaign_goals: 'The goal of the campaign is: ',
                influencer_total: ['I am requesting to work with ','. '],
                budget: ['I am working with a budget of','to implement the campaign. '],
                contact_name: 'My name is',
                email: 'and you can reach me at',
                phone: ['or call me at','.'],
                premade_form: 'Uploading File: ',
                form_link: 'Uploaded File Link: ',
            }
        }
    },
    computed: {
        ...mapGetters([ 'path','cart','status' ])
    },
    methods: {
        get_cart () {
            let steps = []
            for (const f in this.cart) 
                steps.push(this.cart[f])
            return steps
        },
        get_elements (elements) {
            let els = []
            for (const name in elements) {
                els.push({
                    name: name,
                    el: elements[name]
                })
            }
            return els
        },
        render_sentence(el) {

            if (el.value.length == 0) return ''

            let sentence = document.createElement('div')
            let action = document.createElement('a')
            let messaging = document.createElement('span')
            let unit = document.createElement('span')
            let name = el.name

            sentence.classList.add('form-review-sentence')
            action.classList.add('form-review-action')
            action.innerText = this.capitalize(el.value.replace('-',' '))


            switch (name) {
                case 'campaign_type': 
                    action.classList.add('form-review-header')
                    messaging.innerText = this.language[name]

                    sentence.appendChild(action)
                    sentence.appendChild(messaging)
                    break

                case 'influencer_reach': 
                    
                    // let reach = this.translate_reach(el.value)
                    let reach = el.value

                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]
                    action.innerText = this.capitalize(reach)


                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break

                case 'influencer_country': 
                
                    messaging.innerText = this.language[name]
                    let ic_value = el.value
                    if (ic_value.includes('Other')) {
                        ic_value = ic_value.replace('Other',el.other)
                    }
                    action.innerText = this.capitalize(ic_value.replace('-',' '))


                    sentence.appendChild(messaging)
                    sentence.appendChild(action)

                    break

                case 'audience_country': 
                
                    messaging.innerText = this.language[name]
                    let ac_value = el.value
                    if (ac_value.includes('Other')) {
                        ac_value = ac_value.replace('Other',el.other)
                    }
                    action.innerText = this.capitalize(ac_value.replace('-',' '))


                    sentence.appendChild(messaging)
                    sentence.appendChild(action)

                    break

                case 'influencer_specialty': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break

                case 'platform_mod': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break
                case 'product_name': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break
                case 'brand_website': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break
                case 'episode_amount': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break
                case 'digital_influencers': 
                    if (el.value == 'yes') {
                        messaging.innerText = this.language[name]
                        sentence.appendChild(messaging)
                    }
                    break
                case 'num_influencers': 
                    messaging.innerText = this.language[name]
                    sentence.appendChild(action)
                    sentence.appendChild(messaging)
                    break
                case 'show_genre': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break
                case 'audience_age': 
                    // let age = this.translate_age(el.value)
                    let age = el.value

                    messaging.innerText = this.language[name][0]
                    action.innerText = this.capitalize(age)
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break

                case 'brand_name': 
                    let hr = document.createElement('hr')
                    let details = document.createElement('div')
                    details.innerText = 'Campaign Details'

                    messaging.innerText = this.language[name]

                    sentence.appendChild(hr)
                    sentence.appendChild(details)
                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    break

                case 'influencer_total': 
                    let influencers = el.value + 'influencer'
                    if (el.value > 1) influencers += 's'
                    action.innerText = influencers
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break

                case 'budget': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break

                case 'contact_name': 
                    let separator = document.createElement('hr')

                    messaging.innerText = this.language[name]

                    sentence.appendChild(separator)
                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    break

                case 'phone': 
                    messaging.innerText = this.language[name][0]
                    unit.innerText = this.language[name][1]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    sentence.appendChild(unit)
                    break

                case 'form_link':
                    action.innerText = el.value.replace(`C:\\fakepath\\`,'')
                    messaging.innerText = this.language[name]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    break

                case 'premade_form': 
                    action.innerText = el.value.replace(`C:\\fakepath\\`,'')
                    messaging.innerText = this.language[name]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)
                    break

                default: 
                    action.innerText = this.capitalize(el.value.replace('-',' '))
                    messaging.innerText = this.language[name]

                    sentence.appendChild(messaging)
                    sentence.appendChild(action)

                    break
            }

            return sentence
        },
        // translate_reach (value) {
        //     switch(value) {
        //         case 'starter':     return '5k - 50k'
        //         case 'amateur':     return '50k - 150k'
        //         case 'pro':         return '150k - 500k'
        //         case 'celebrity':   return '500k - 1.5m'
        //         case 'star':        return '1.5m - 10m'
        //         case 'legend':      return '10m+'
        //     }
        // },
        // translate_age (value) {
        //     switch(value) {
        //         case 'teen':     return '13 - 17'
        //         case 'college':  return '18 - 24'
        //         case 'young':    return '25 - 34'
        //         case 'mature':   return '35 - 44'
        //         case 'adult':    return '45 - 54'
        //         case 'senior':   return '55 - 64'
        //         case 'elder':    return '65+'
        //     }
        // },
        jump (el) {
            this.$store.dispatch('set_step',el.step)
        },
        capitalize (word) {
            return word.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
        },
        submit (e) {
            e.preventDefault()
            //validate
            if (this.is_validated()) {
                this.$store.dispatch('submit',{
                    e: e,
                    files: this.files,
                })
            }
            else {
                //show invalid 
            }
        },
        is_validated () {
            for (const field of this.cart) {
                if (field.valid == false)
                    return false
            }
            return true
        },
        // render_edit () {
        //     return this.el !== undefined ? this.el.outerHTML : ''
        // },
        // edit (el) {


        //     //change class for editing modal
        //     this.editing = true
            
            
        //     switch(el.type) {
        //         case 'radio': this.el = this.convert_to_select(el); break
        //         case 'checkbox': this.el = ''; break
        //         default: this.el = el; break
        //     }
        //     this.step = el.step

        // },
        // convert_to_select (el) {
        //     let select = document.createElement('select')
        //     select.name = el.name
        //     select.required = el.required
        //     for (const e of el.options) {
        //         let option = document.createElement('option')
        //         let val = e.replace('-',' ')
        //         option.value = val
        //         option.text = val
        //         option.checked = e == el.value
        //         select.appendChild(option)
        //     }
        //     return select
        // },
        // async update (e) {


        //     if (this.el.name == 'campaign_type') {

        //         this.$store.dispatch('set_status','');
        //         this.$store.dispatch('generate_path',this.el.value.replace('_','-'));
                
        //         let next = this.path.indexOf(this.step) + 1
        //         this.$store.dispatch('set_step',this.path[next]);

        //         return
        //     }

        //     //set new value
        //     let name = this.el.name
        //     let value = e.target.value
        //     this.cart[this.step][name].value = value
        //     this.editing = false
        // },
    },
}
</script>
<style>
</style>