<template>
    <picture :class="getClass()">

        <source media="(min-width: 1441px)" :srcset="getImageScaled(4)">
        <source media="(min-width: 961px)"  :srcset="getImageScaled(3)">
        <source media="(min-width: 481px)"  :srcset="getImageScaled(2)">
        <source media="(min-width: 0px)"    :srcset="getImageScaled(1)">

        <img    :srcset="getImageScaled(4) + ' 1920w,' + 
                         getImageScaled(3) + ' 1440w,' +
                         getImageScaled(2) + ' 960w,' + 
                         getImageScaled(1) + ' 480w'"

                sizes="(min-width: 1441px) 1920px,
                       (min-width: 961px) 1440px,
                       (min-width: 481px) 960px,
                       (min-width: 0) 480px"

                :src="getImageScaled(1)">
                    
    </picture>
</template>
<script>
    export default {
        name: 'background',
        props: [ 'img' ],
        methods: {
            getImageScaled (scale) {
                return require('../asset/img/' + this.img + '@' + scale + 'x.png')
            },
            getClass () {
                return 'form-' + this.img
            },
        }
    }
</script>