<template>
    <div class="form-response" :class="get_response_status()">
        <div class="form-messaging" v-if="is_success()">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            <h3>Your submission is now being processed!</h3>
            <h4>Thank you for taking the time to create your campaign with Alfan.</h4>
            <button @click="reset()">Create Another Campaign</button>
        </div>
        <div class="form-messaging" v-if="!is_success()">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <h3>The submission could not be processed.</h3>
            <h4>{{ get_error_message() }}</h4>
            <button @click="review()">Review Again</button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'form-response',
    computed: {
        ...mapGetters([
            'response'
        ]),
    },
    methods: {
        get_response_status () {

            if (this.is_success()) return 'submitted'
            return 'rejected'
            
        },
        is_success () {
            return this.has_response() && this.response.data.success
        },
        has_response () {
            return this.response.status == 200
        },
        has_data () {
            return this.response.data != undefined
        },
        get_error_message () {
            if (this.has_response() && this.has_data()) return this.response.data.message
            return `There was an issue with the server, try to submit again and if you keep having issues please contact us.`
        },
        review () {
            this.$store.dispatch('set_status','reviewing')
        },
        reset () {
            location.reload()
        },
    },
}
</script>
<style>
</style>