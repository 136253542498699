<template>
    <div class="form-stage" :class="get_status()">
        <div class="form-frame" :class="get_navigation_class()">
            <transition name="fade-quick" mode="out-in">
            <div id="intro"                     class="form-slide form-column" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()" 
                                                :class="get_active('intro')" v-if="reveal('intro')" key="1">
                <div class="form-messaging">
                    <h4>Power your campaign with our</h4>
                    <div class="alfan-title">
                        <div class="alfan-stripe"></div>
                        <h1>Campaign Creator</h1>
                    </div>
                    <p>Complete our form to register your campaign with Alfan! Our full arsenal of tools is at your disposal. Fill in all the required information (marked with white dot) to move forward and you may click on the steps to navigate the form at any time. Once all the information is successfully entered, you will be able to review before creating your campaign.</p>
                </div>
                <FileLink :file="fields.form_link" v-on:click="generate_path" />
                <!-- <File :file="fields.premade_form" v-on:click="upload" v-if="!is_safari()" /> -->
                <Radio :radio="fields.campaign_type" v-on:click="generate_path" />
            </div>
            </transition>
            <transition name="fade-quick" mode="out-in">
            <div id="influencer-marketing"      class="form-slide form-column" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()" 
                                                :class="get_active('influencer-marketing')" v-if="reveal('influencer-marketing')" key="2">
                <h2>You Have Chosen Influencer Marketing!</h2>
                <Radio :radio="fields.campaign_sub" v-on:click="jump()" />
            </div>
            </transition>
            <transition name="fade-quick" mode="out-in">
            <div id="performance-marketing"     class="form-slide form-column" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()" 
                                                :class="get_active('performance-marketing')" v-if="reveal('performance-marketing')" key="3">
                <h2>Performance Marketing</h2>
                <div class="form-column">
                    <h4>What is your offer?</h4>
                    <Radio  :radio="fields.cost_type" />
                    <hr>
                    <Textbox :textbox="fields.cost_amount" />
                </div>
            </div>
            </transition>
            <transition name="fade-quick" mode="out-in">
            <div id="digital-series"            class="form-slide form-column" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()"
                                                :class="get_active('digital-series')" v-if="reveal('digital-series')" key="4">
                <h2>Digital Series Details</h2>
                <div class="form-group">
                    <h4>How many episodes do you want for your campaign?</h4>
                    <Textbox :textbox="fields.episode_amount" />
                </div>
                <div class="form-group">
                    <h4>Do you want to include influencers?</h4>
                    <Radio :radio="fields.digital_influencers" v-on:click="show_influencers" />
                    <Textbox :textbox="fields.num_influencers" v-if="influencers"/>
                </div>
                <div class="form-group">
                    <h4>What genre is the show?</h4>
                    <Checkbox :checkbox="fields.show_genre" />
                </div>
                <div class="form-group">
                    <h4>Reference or description of the show</h4>
                    <Textarea :textarea="fields.show_description" />
                </div>
            </div>
            </transition>
            <transition name="fade-quick" mode="out-in">
            <div id="platform"                  class="form-slide" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()" 
                                                :class="get_active('platform')" v-if="reveal('platform')" key="5">
                <Ribbon :ribbons="fields.social_platform" :mod="fields.platform_mod" />
            </div>
            </transition>
            <transition name="fade-quick" mode="out-in">
            <div id="strategy"                  class="form-slide form-column" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()" 
                                                :class="get_active('strategy')" v-if="reveal('strategy')" key="6">
                <div class="form-messaging"><h2>Let's talk strategy!</h2></div>
                
                <hr>
                <h3>Define Your Influencer</h3>
                <div class="form-column">
                    <Checkbox :checkbox="fields.influencer_specialty" />
                </div>
                <div class="form-column">
                    <SelectMultiple :select="fields.influencer_reach"/>
                </div>
                <div class="form-group">
                    <h5>Influencer Gender</h5>
                    <Checkbox :checkbox="fields.influencer_gender" />
                </div>
                <div class="form-column">
                    <SelectMultiple :select="fields.influencer_country"/>
                </div>
                <hr>
                <h3>Select Your Target Audience</h3>
                <div class="form-column">
                    <SelectMultiple :select="fields.audience_country"/>
                </div>
                <div class="form-column">
                    <Checkbox :checkbox="fields.audience_age"/>
                </div>
            </div>
            </transition>
            <transition name="fade-quick" mode="out-in">
            <div id="details"                   class="form-slide form-column" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()" 
                                                :class="get_active('details')" v-if="reveal('details')" key="7">
                <h2>Provide Your Campaign Details</h2>
                <div class="form-column">
                    <h4>What is the name of your brand?</h4>
                    <Textbox :textbox="fields.brand_name" />
                </div>
                <div class="form-column">
                    <h4>and what is the name of your product?</h4>
                    <Textbox :textbox="fields.product_name" />
                </div>
                <div class="form-column">
                    <h4>Where can people visit your website?</h4>
                    <Textbox :textbox="fields.brand_website" />
                </div>
                <div class="form-column">
                    <h4>Tell us a bit about your goals for the campaign?</h4>
                    <Textarea :textarea="fields.campaign_goals" />
                </div>
                <div class="form-column">
                    <h4>Number of influencers you would like to have?</h4>
                    <Textbox :textbox="fields.influencer_total" />
                </div>
                <div class="form-column">
                    <h4>What kind of budget are you working with for this campaign?</h4>
                    <Textbox :textbox="fields.budget" />
                </div>
            </div>
            </transition>
            <transition name="fade-quick" mode="out-in">
            <div id="submission"                class="form-slide form-column" @click="select($event)" @mouseover="hover($event)" @mouseout="unhover()" 
                                                :class="get_active('submission')" v-if="reveal('submission')" key="8">
                <h2>Almost Done! We just need your contact information and we are ready to review.</h2>
                <div class="form-column">
                    <h4>What is your name?</h4>
                    <Textbox :textbox="fields.contact_name" />
                </div>
                <div class="form-column">
                    <h4>Please provide us with your official email.</h4>
                    <Textbox :textbox="fields.email" />
                </div>
                <div class="form-column">
                    <h4>and finally a phone number where we can reach you.</h4>
                    <Textbox :textbox="fields.phone" />
                </div>
            </div>
            </transition>
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex'
// import Radio from '@/comp/InputRadio.vue'
import Radio from '@/comp/input/InputRadio.vue'
import Checkbox from '@/comp/input/InputCheckbox.vue'
// import Ribbon from '@/comp/InputRibbon.vue'
import Ribbon from '@/comp/input/InputRibbon.vue'
// import Select from '@/comp/InputSelect.vue'
import Select from '@/comp/input/InputSelect.vue'
import SelectMultiple from '@/comp/input/InputSelectMultiple.vue'
// import Textbox from '@/comp/InputTextbox.vue'
import Textbox from '@/comp/input/InputTextbox.vue'
// import Textarea from '@/comp/InputTextarea.vue'
import Textarea from '@/comp/input/InputTextarea.vue'
import File from '@/comp/input/InputFile.vue'
import FileLink from '@/comp/input/InputFileLink.vue'

export default {
    name: 'form-stage',
    data () { return {
        influencers: false,
    }},
    computed: {
        ...mapGetters([
            'step',
            'path',
            'status',
            'fields',
        ]),
    },
    methods: {
        existsStep () {
            return this.step === undefined || this.step.length < 1 ? false : true
        },
        reveal (id) {
            return this.isStep(id) || this.isNavigating(id) || this.isReviewing(id)
        },
        get_active (id) {
            return this.reveal(id) ? 'active' : ''
        },
        select (e) {
            let current = this.path.indexOf(this.step)
            let index = this.path.indexOf(e.target.id)


            if (this.status == 'navigating') {
                this.$store.dispatch('set_step',e.target.id)
            }
        },
        hover (e) {
            if (this.status == 'navigating') {
                this.$store.dispatch('set_hover',e.target.id)
            }
        },
        unhover () {
            this.$store.dispatch('set_hover','')
        },
        isStep(id) {
            return this.step == id
        },
        is_safari () {
            let vendor = navigator.vendor.toLowerCase()
            let exists = vendor.indexOf('apple') > -1
            return exists
        },
        isNavigating (id) {

            let navigating = this.status == 'navigating'
            let isInPath = this.path.findIndex(row => row.name == id) > -1

            return navigating && isInPath
        },
        isReviewing (id) {

            let reviewing = this.status == 'reviewing' ? true : false
            let isInPath = this.path.indexOf(id) > -1 ? true : false

            return reviewing && isInPath
        },
        generate_path (id) {
            this.$store.dispatch('generate_path',id).then(()=>{
                this.jump()
            })
        },
        jump () {
            this.$store.dispatch('next')
        },
        clickRibbon (e) {
            let el = e.target; 
            let parent = e.target.parentElement.parentElement; 

            //set ribbon active
            let buttons = document.querySelectorAll('.form-button-wrapper')
            let inputs = document.querySelectorAll('.form-button-wrapper input')

            this.clear_all_active(buttons)
            this.clear_all_checked(inputs)
            
            el.checked = true
            parent.classList.add('active')


            if (this.is_valid_page()) {
                // this.$store.dispatch('update_direction')
            }
        },
        clear_all_active (list) {
            for (var i = 0; i < list.length; i++) {
                list[i].classList.remove('active')
            }
        },
        clear_all_checked (list) {
            for (var i = 0; i < list.length; i++) {
                list[i].checked = false
            }
        },
        show_influencers (status) {
            this.influencers = status == 'yes'
            if (this.influencers) this.fields.num_influencers.required = true
            else this.fields.num_influencers.required = false
        },
        get_value (el) {

            
            switch (el.type) {
                case 'textarea': 
                case 'text':
                    return el.value
                case 'radio':
                    // let e = 
                    let parent = el.parentElement.parentElement.parentElement
                    let e = [...parent.querySelectorAll('[name='+el.name+']')]
                    let checked = e.filter(node => node.checked)
                    return e.value
                case 'select':
                    return el.children[el.selectedIndex].value
                case 'checkbox':
                    return ''
                default: return ''
            }

        },
        get_navigation_class () {
            return 's' + this.path.length
        },
        // async animation_step_out () {

        //     let els = this.grabEls(this.$refs.stage)
            
        //     this.add_class(els, 'a-out')
        //     await this.wait(this.ani_time)
        //     this.remove_class(els, 'a-out')

        // },
        wait(time) {
            return new Promise(res => setTimeout(res,time))
        },
        get_status () {
            if (this.status == 'navigating') {
                setTimeout(function () {
                    let slides = document.querySelectorAll('.form-slide')
                    for (const slide of slides) {
                        slide.classList.add('expand')
                    }
                },300)
            }
            else {
                let slides = document.querySelectorAll('.form-slide')
                for (const slide of slides) {
                    slide.classList.remove('expand')
                }
            }
            return this.status
        },
    },
    updated () {
    },
    components: {
        File,
        FileLink,
        Radio,
        Ribbon,
        Select,
        Textbox,
        Textarea,
        Checkbox,
        SelectMultiple,
    }
}
</script>