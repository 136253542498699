<template>
    <div class="input-field input-textbox" :class="get_state()" ref="root">

        <div    class="input-header" v-if="textbox.header">
                {{ textbox.header }} </div>

        <label :for="textbox.name" v-if="textbox.label">
                {{ get_label() }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <input  @focus="wake()"
                @blur="sleep()"
                @input="update()"
                :type="textbox.type"
                :name="textbox.name"  
                :required="textbox.required" 
                :placeholder="textbox.placeholder"
                v-model="textbox.value" >
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-textbox',
    data () { return {
        state: [],
        response: '',
        peek: false,
    }},
    props: ['textbox'],
    computed: {
        ...mapGetters([ 'cart','fields','status','currency' ])
    },
    methods: {
        wake () {
            this.state.push('active')
        },
        sleep () {

            if (this.is_active()) 
                this.remove_state('active')

            this.notify()
            
        },
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.filter(el => el.name == this.textbox.name).length > 0
        },
        is_notifying () {

            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.textbox.required

            let notify = this.is_created() ? this.get_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        get_created () {
            return this.cart.filter(el => el.name == this.textbox.name)[0]
        },
        get_state () {

            if (this.state.length == 0 && !this.peek) {
                this.set_state()
                this.peek = true
            }

            return this.state.join(' ')

        },
        set_state () {

            let valid = this.is_created() ? this.get_created().valid : this.textbox.valid

            if (valid && !this.has_state('valid')) {
                this.state.push('valid')
                this.remove_state('invalid')
            }
            
            else if (this.textbox.value.length == 0 || 
                    (!valid && !this.textbox.required)) {
                    this.remove_state('valid')
                    this.remove_state('invalid')
            }

            else if (!valid && this.textbox.required && !this.has_state('invalid')) {
                this.state.push('invalid')
                this.remove_state('valid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            if (this.textbox.pattern !== undefined)
                switch (this.textbox.pattern) {
                    case 'currency': return 'Required Field: The currency is submitted as shown in the label. Only numbers, commas & decimals allowed.'
                    default: return `Required Field: Don't leave empty`
                }

            else switch (this.textbox.type) {
                case 'email': return 'Required Field: Your email format should be in the following style: example@domain.com'; break
                case 'phone': return 'Required Field: Numbers and Dashes. ex.555-555-5555.'; break
                default: return `Required Field: Don't leave empty`
            }

        },
        notify () {

            if (this.textbox.valid) 
                this.reset_response_state()
            
            else if (!this.textbox.valid && !this.textbox.required) 
                this.reset_response_state()
            
            else if (!this.textbox.valid) 
                this.response = this.get_response()
            
        },
        get_pattern () {
            
            let pattern = ''

            if (this.textbox.pattern !== undefined) 
                switch (this.textbox.pattern) {
                    case 'currency': pattern = /^[0-9]+(\.[0-9]{1,2})?$/gm; break
                    case 'integer': pattern = /^\d+$/gm; break
                    case 'float': pattern = /^[+-]?\d+(\.\d+)?$/gm; break
                    default: pattern = ''; break
                }

            else switch (this.textbox.type) {
                // case 'email': pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/gm; break
                case 'email': pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; break
                case 'phone': pattern = /^(?=.*[0-9])[- +()0-9]+$/g; break
                default: pattern = ''; break
            }


            return new RegExp(pattern)

        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        reset_response_state () {

            this.response = ''

            let bundle = {
                name: this.textbox.name,
                type: this.textbox.type,
                step: this.textbox.step,
                value: this.textbox.value,
                header: this.textbox.header,
                label: this.textbox.label,
                options: this.textbox.options,
                theme: this.textbox.theme,
                required: this.textbox.required,
                valid: this.is_created() ? this.get_created().valid : this.textbox.valid,
                notify: false,
            }

            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('set_status', '')

        },
        update (index) {
            let bundle = {
                name: this.textbox.name,
                type: this.textbox.type,
                step: this.textbox.step,
                value: this.textbox.value,
                header: this.textbox.header,
                label: this.textbox.label,
                options: this.textbox.options,
                theme: this.textbox.theme,
                required: this.textbox.required,
                valid: this.validate(),
                notify: this.textbox.notify,
            }

            this.$store.dispatch('update_field', bundle)

            this.$emit('click')

            this.set_state()

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate (e) {

            let value = String(this.textbox.value).toLowerCase()

            let valid = this.get_pattern().test(String(value).toLowerCase())
            


            return valid

        },
        get_label () {

            let label = this.textbox.label
            
            if (this.textbox.unit === undefined) return label

            switch (this.textbox.unit) {
                case 'global_currency': 
                    if (this.currency.length > 0)
                        label += ' (' + this.currency + ')'
            }

            return label

        },
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
}
</script>