<template>
    <div class="input-field input-checkbox" :class="get_state()" ref="root">

        <div    class="input-header" v-if="checkbox.header">
                {{ checkbox.header }} </div>

        <label :for="checkbox.name" v-if="checkbox.label">
                {{ checkbox.label }} </label>

        <transition name="fade">
             <div class="input-response" v-if="is_notifying()" @mouseover="reset_response_state($event)">
                {{ response }} </div> </transition>

        <div class="input-frame" :class="checkbox.theme">
            <div    v-for="(option,i) of checkbox.options" :key="i" 
                    :class="is_selected(option)"
                    class="input-button" 
                    @click="update(i)" >

                    <div class="input-button-icon" v-if="exists_icon(option)" v-html="get_icon(option)"></div>
                    {{ option }} </div> </div>

        <input  type="text"
                class="hidden"
                :name="checkbox.name"  
                :value="get_value()"
                :required="checkbox.required" >
                
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'input-checkbox',
    data () { return {
        state: [],
        response: '',
    }},
    props: ['checkbox'],
    computed: {
        ...mapGetters([ 'cart','fields','status' ])
    },
    methods: {
        is_active () {
            return this.has_state('active')
        },
        is_created () {
            return this.cart.findIndex(el => el.name == this.checkbox.name) > -1
        },
        is_selected (option) {
            let exists = false
            if (this.is_created()) {
                let arr = this.get_created().value.split(',')
                exists = arr.findIndex(o => o == option) > -1
            }
            return exists ? 'active' : ''
        },
        is_notifying () {

            let is_valid = this.is_created() && this.get_created().valid
            let is_required = this.checkbox.required

            let notify = this.is_created() ? this.get_created().notify : false
            let is_notifying = this.status == 'notifying' && !is_valid && is_required

            let should_respond = notify || is_notifying

            if (should_respond) {
                this.notify()
                return true
            }

            return false

        },
        get_created () {
            return this.cart.filter(el => el.name == this.checkbox.name)[0]
        },
        get_value () {

            let value = this.is_created() ? this.get_created().value : ''

            if (value.length == 0) value = []
            else value.split(',')

            return this.is_created() ? value : ''

        },
        get_state () {
            return this.state.join(' ')
        },
        set_state () {

            let valid = this.is_created() ? this.get_created().valid : false

            if (valid && !this.has_state('valid')) {
                this.state.push('valid')
            }
            
            else if (!valid && !this.checkbox.required) {
                this.remove_state('valid')
            }

            else if (!valid && this.checkbox.required && !this.has_state('invalid')) {
                this.state.push('invalid')
            }

        },
        has_state (name) {
            return this.state.indexOf(name) > -1
        },
        remove_state (name) {
            this.state = this.state.filter(c => c !== name)
        },
        get_response () {

            switch (this.checkbox.name) {
                default: return 'Make sure you pick at least one option, this is a required field.'; break
            }

        },
        notify () {

            if (this.checkbox.valid) 
                this.reset_response_state()
            
            else if (!this.checkbox.valid && !this.checkbox.required) 
                this.reset_response_state()
            
            else if (!this.checkbox.valid) 
                this.response = this.get_response()
            
        },
        get_response_state () {
            return this.response.length > 0 ? 'active' : ''
        },
        reset_response_state () {

            this.response = ''

            let bundle = {
                name: this.checkbox.name,
                type: this.checkbox.type,
                step: this.checkbox.step,
                value: this.get_value(),
                header: this.checkbox.header,
                label: this.checkbox.label,
                options: this.checkbox.options,
                theme: this.checkbox.theme,
                required: this.checkbox.required,
                valid: this.is_created() ? this.get_created().valid : this.checkbox.valid,
                notify: false,
            }

            this.$store.dispatch('update_field', bundle)
            this.$store.dispatch('set_status', '')

        },
        exists_icon (option) {
            return this.get_icon(option).length > 0
        },
        get_icon (option) {
            switch(option) {
                case 'sports': return `<i class="fa fa-futbol-o" aria-hidden="true"><i>`
                case 'gaming': return `<i class="fa fa-gamepad"><i>`
                case 'automotive': return `<i class="fa fa-car"><i>`
                case 'fashion': return `<i class="fa fa-shopping-bag"><i>`
                case 'beauty': return `<i class="fa fa-diamond"><i>`
                case 'lifestyle': return `<i class="fa fa-home"><i>`
                case 'travel': return `<i class="fa fa-plane"><i>`
                case 'family': return `<i class="fa fa-group"><i>`
                case 'music': return `<i class="fa fa-headphones"><i>`
                case 'food': return `<i class="fa fa-cutlery"><i>`
                case 'health': return `<i class="fa fa-heartbeat"><i>`
                case 'business': return `<i class="fa fa-suitcase"><i>`
                default: return ''
            }
        },
        update (index) {

            let name = this.checkbox.options[index]
            let valid = this.checkbox.options.indexOf(name) > -1
            let value = this.toggle_value(name)

            if (value.length == 0) valid = false

            let bundle = {
                name: this.checkbox.name,
                type: this.checkbox.type,
                step: this.checkbox.step,
                value: value,
                header: this.checkbox.header,
                label: this.checkbox.label,
                options: this.checkbox.options,
                theme: this.checkbox.theme,
                required: this.checkbox.required,
                valid: valid,
                notify: this.checkbox.notify,
            }

            this.$store.dispatch('update_field', bundle)

            this.$emit('click',value)

            this.set_state()

        },
        toggle_value (name) {

            let value = this.is_created() ? this.get_created().value.split(',') : []

            if (this.is_created()) {
                if (value.indexOf(name) < 0)
                    value.push(name)
                else
                    value.splice(value.indexOf(name),1)
            }
            else 
                value.push(name)


            value = value.join(',')
            


            return value

        },
        animate () {

            let els = [...this.$refs.root.querySelectorAll('*')]

            let delay = 0.2
            let increment = 0.01
            let offset = 2

            for (const el of els) {
                el.style.animationDelay = delay + 's'
                delay += increment * offset
            }

        },
        validate () {
            this.set_state()
        },
    },
    mounted () {

        this.animate()

        if (this.is_created())
            this.validate()
            
    },
}
</script>