<template>
    <div class="form-imagery">
        <transition name="fade">
            <Background v-if="isIntro()" :img="images.intro" />
        </transition>
        <transition name="fade">
            <Background v-if="!isIntro()"  :img="images.default" />
        </transition>
    </div>
</template>
<script>

    import { mapGetters } from 'vuex'
    import Background from '@/comp/Background.vue'
    export default {
        name: 'form-imagery',
        computed: {
            ...mapGetters([ 'step','images' ])
        },
        methods: {
            existsStep() {
                return this.step === undefined || this.step.length < 1 ? false : true
            },
            isIntro () {
                return this.existsStep() && this.step == 'intro' ? true : false
            },
        },
        components: {
            Background,
        }
    }

</script>
<style scoped>

    .form-imagery {
        position: absolute;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        user-select: none;
        pointer-events: none;
        z-index: 0;
    }

    .form-imagery picture {
        position: absolute; 
        top: 0;
        left: 0; 
        height: 100%; 
        width: 100%;
        display: flex;
        align-items: flex-end;
    }

    .form-imagery img {height: auto; width: 100%;}

</style>